import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/privacy-policy";
import CookiePolicy from "./pages/cookie-policy";
import Footer from "./components/Footer";
import BackToTopButton from "./components/BackToTopButton";
import React, { useEffect } from "react";
import Blog from "./pages/Blog";
import DetailPage from "./components/DetailPage";
import LandingPage from "./pages/Landing";
import Video from "./pages/Video";

function App() {
  const navigate = useNavigate();
 
  useEffect(() => {
    const isFirstVisit = sessionStorage.getItem("firstVisit");
 
    if (!isFirstVisit) {
      navigate("/LandingPage", { replace: true });
      sessionStorage.setItem("firstVisit", "true");
    }
  }, [navigate]);
 

  return (
    <>
      {/* {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <img
              src="img/popup/popup.jpg"
              alt="Akcija"
              className="popup-image"
            />
            <button
              onClick={closePopup}
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>
      )} */}
      <div className="app">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Video" element={<Video />} />
          <Route path="/LandingPage" element={<LandingPage />} />
          <Route path="/detail/:id" element={<DetailPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </Routes>
        <Footer />
        <BackToTopButton />
      </div>
    </>
  );
}

export default App;
