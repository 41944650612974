import React from "react";
import data from "../data/video.json";

const VideoComponent = () => {
  return data.map((videoData, index) => (
    <div
      style={{
        backgroundColor: index % 2 === 0 ? "#ffffff" : "#e5f6f5",
        padding: "20px",
        paddingTop: "90px",
        paddingBottom: "90px"
      }}
    >
      <div className="container video2">
        <div key={videoData.id} className="row">
          {/* Uslovno pozicioniranje */}
          <div
            className={`col-md-6 p-0 d-flex justify-content-center align-items-center ${
              index % 2 === 0 ? "order-md-1" : ""
            }`}
          >
            <video
              src={videoData.video}
              alt="Video"
              style={{ width: "360px", height: "auto" }}
              controls
            />
          </div>

          <div
            className={`col-md-6 d-flex justify-content-center align-items-center ${
              index % 2 !== 0 ? "order-md-1" : ""
            }`}
          >
            <div>
              <h2
                className="blog-text1"
                style={{ color: "#06a89e", fontSize: "25px" }}
              >
                {videoData.title}
              </h2>
              <p>{videoData.text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

export default VideoComponent;
