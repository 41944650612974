import React from 'react';

const HealthAdvice = () => {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 text-center">
          <h4 className="text-muted  blog-text blog-text1"style={{ fontSize: '1.2rem' }}>Profesionalni zdravstveni saveti</h4>
          <h1 className="display-8 font-weight-bold blog-text blog-text1">Zdrav način života</h1>
          <p className="lead blog-text" style={{ fontSize: '1rem', maxWidth: '80%', margin: '0 auto' }}>
          Da biste očuvali dobro zdravlje, važno je usvojiti zdrave navike poput uravnotežene ishrane, redovne fizičke aktivnosti i kvalitetnog sna. Izbegavanje stresa, adekvatna hidratacija i redovni preventivni pregledi dodatno doprinose očuvanju vitalnosti i dugovečnosti.          </p>
        </div>
      </div>
    </div>
  );
};

export default HealthAdvice;
