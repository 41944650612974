import React from "react";
import { useParams } from "react-router-dom";
import data from "../data/blog.json";

const DetailPage = () => {
  const { id } = useParams(); // Dohvata ID iz URL-a
  const item = data.find((d) => d.id === parseInt(id)); // Pronalazi odgovarajući podatak

  if (!item) {
    return (
      <div className="container mt-5">
        <h3>Podaci nisu pronađeni.</h3>
      </div>
    );
  }

  return (
    <div className="container-blog">
      <div className="container mt-5 ">
        {/* Gornji deo stranice */}
        <div className="row align-items-center">
          <div className="col-md-6">
            {/* <p className="lead blog-text">{item.small}.</p> */}
            <div
              className="lead blog-text"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </div>
          <div className="col-md-6">
            <img
              src={item.image}
              alt={item.title}
              className="img-fluid rounded"
              style={{ objectFit: "cover",  width: "100%" }}
            />
          </div>
        </div>

        {/* Donji deo stranice */}
        <div className="mt-5">
          {/* <h2 className="blog-text">{item.title}</h2> */}
          <div
            className="blog-text"
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
